import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var _excluded = ["onClick", "ref", "onBlur", "style", "className"],
    _excluded2 = ["onMouseLeave", "ref"],
    _excluded3 = ["onMouseDown"],
    _excluded4 = ["onMouseEnter", "onClick"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { createAction } from 'typesafe-actions';
import { useClickAway, useIsomorphicLayoutEffect, useKey } from 'react-use';
import { usePopper } from 'react-popper';
import { produce, original } from 'immer';
var controlledKeys = [" ", "ArrowDown", "ArrowUp", "Enter", "Escape"];
export var CLICKED_AWAY = "CLICKED_AWAY";
export var CONTROL_BLURRED = "CONTROL_BLURRED";
export var CONTROL_CLICKED = "CONTROL_CLICKED";
export var ITEM_CLICKED = "ITEM_CLICKED";
export var ITEM_MOUSE_ENTERED = "ITEM_MOUSE_ENTERED";
export var KEY_PRESSED = "KEY_PRESSED";
export var MENU_MOUSE_LEFT = "MENU_MOUSE_LEFT";
export var MENU_CONTAINER_MOUSE_DOWN = "MENU_CONTAINER_MOUSE_DOWN";
export var MOUSE_UP = "MOUSE_UP";
export var clickedAwayAction = createAction(CLICKED_AWAY)();
export var controlBlurredAction = createAction(CONTROL_BLURRED)();
export var controlClickedAction = createAction(CONTROL_CLICKED)();
export var itemClickedAction = createAction(ITEM_CLICKED)();
export var itemMouseEnteredAction = createAction(ITEM_MOUSE_ENTERED)();
export var keyPressedAction = createAction(KEY_PRESSED)();
export var menuMouseLeftAction = createAction(MENU_MOUSE_LEFT)();
export var menuContainerMouseDownAction = createAction(MENU_CONTAINER_MOUSE_DOWN)();
export var mouseUpAction = createAction(MOUSE_UP)();

var updateSelectedState = function updateSelectedState(draft, option, _ref) {
  var _original;

  var multiselect = _ref.multiselect;
  var originalSelected = (_original = original(draft.selected)) !== null && _original !== void 0 ? _original : [];
  var index = originalSelected.findIndex(function (item) {
    return item === option;
  });

  if (index === -1) {
    var _original2;

    draft.previous = (_original2 = original(draft.selected)) !== null && _original2 !== void 0 ? _original2 : null;
    if (!multiselect) draft.selected = [];
    draft.selected.push(option);
  } else if (multiselect) {
    var _original3;

    draft.previous = (_original3 = original(draft.selected)) !== null && _original3 !== void 0 ? _original3 : null;
    draft.selected.splice(index, 1);
  }
};

var handlePressedKey = function handlePressedKey(draft, _ref2, config) {
  var key = _ref2.key,
      isControlFocused = _ref2.isControlFocused;

  switch (key) {
    case "Escape":
      {
        if (draft.open) {
          draft.open = false;
          draft.focused = draft.lastTriggeredItem;
        }

        break;
      }

    case " ":
    case "Enter":
      {
        if (isControlFocused) {
          var originalFocused = draft.focused ? original(draft.focused) : draft.focused;
          var originalOpened = draft.open;
          var originalOptions = original(draft.options);

          if (!originalOpened && originalOptions) {
            draft.focused = originalOptions.length > 0 ? originalOptions[0] : null;
          }

          if (originalOpened && originalFocused) {
            if (config.selectable) {
              updateSelectedState(draft, originalFocused, config);
            }

            draft.lastTriggeredItem = draft.focused;

            if (config.closeOnSelect) {
              draft.open = false;
            }
          }
        }

        break;
      }

    case "ArrowDown":
      {
        if (isControlFocused) draft.open = true;

        if (draft.open) {
          var _original4;

          var _originalFocused = draft.focused ? original(draft.focused) : draft.focused;

          var _originalOptions = (_original4 = original(draft.options)) !== null && _original4 !== void 0 ? _original4 : [];

          var focusedIndex = _originalOptions.findIndex(function (o) {
            return o === _originalFocused;
          });

          if (focusedIndex < draft.options.length - 1) {
            draft.focused = _originalOptions[focusedIndex + 1];
          }
        }

        break;
      }

    case "ArrowUp":
      {
        if (isControlFocused) draft.open = true;

        if (draft.open) {
          var _original5;

          var _originalFocused2 = draft.focused ? original(draft.focused) : draft.focused;

          var _originalOptions2 = (_original5 = original(draft.options)) !== null && _original5 !== void 0 ? _original5 : [];

          var _focusedIndex = _originalOptions2.findIndex(function (o) {
            return o === _originalFocused2;
          });

          if (_focusedIndex === -1 && _originalOptions2.length > 0) _focusedIndex = _originalOptions2.length;

          if (_focusedIndex > 0) {
            draft.focused = _originalOptions2[_focusedIndex - 1];
          }
        }

        break;
      }

    default:
  }
};

export var createMenuReducer = function createMenuReducer(config) {
  return produce(function (state, action) {
    switch (action.type) {
      case CLICKED_AWAY:
        {
          state.open = false;
          return;
        }

      case CONTROL_CLICKED:
        {
          state.open = !state.open;
          return;
        }

      case ITEM_CLICKED:
        {
          state.lastTriggeredItem = action.payload;

          if (config.closeOnSelect && state.open && !config.multiselect) {
            state.open = false;
          }

          if (!config.selectable) {
            return;
          }

          updateSelectedState(state, action.payload, config);
          return;
        }

      case CONTROL_BLURRED:
        {
          state.open = false;
          return;
        }

      case ITEM_MOUSE_ENTERED:
        {
          state.focused = action.payload;
          return;
        }

      case MENU_MOUSE_LEFT:
        {
          state.focused = null;
          return;
        }

      case KEY_PRESSED:
        {
          handlePressedKey(state, action.payload, config);
          return;
        }

      case MENU_CONTAINER_MOUSE_DOWN:
        {
          state.menuContainerMouseDown = true;
          return;
        }

      case MOUSE_UP:
        {
          state.menuContainerMouseDown = false;
          return;
        }

      default:
        {
          throw new Error("Unhandled action type: ".concat(JSON.stringify(action)));
        }
    }
  });
};
export var init = function init(_ref3) {
  var initialSelected = _ref3.initialSelected,
      initialOpen = _ref3.initialOpen,
      options = _ref3.options;
  return {
    selected: initialSelected,
    previous: null,
    lastTriggeredItem: null,
    open: initialOpen,
    options: options,
    focused: null,
    menuContainerMouseDown: false
  };
};
var offsetModifier = {
  name: "offset",
  options: {
    offset: [0, 10]
  }
};
export var DEFAULT_POPPER_MODIFIERS = [offsetModifier];

var useMenuReducer = function useMenuReducer(_ref4) {
  var closeOnSelect = _ref4.closeOnSelect,
      interactive = _ref4.interactive,
      multiselect = _ref4.multiselect,
      selectable = _ref4.selectable,
      options = _ref4.options,
      externalReducer = _ref4.reducer,
      externalInit = _ref4.init,
      _ref4$initialOpen = _ref4.initialOpen,
      initialOpen = _ref4$initialOpen === void 0 ? false : _ref4$initialOpen,
      _ref4$initialSelected = _ref4.initialSelected,
      initialSelected = _ref4$initialSelected === void 0 ? [] : _ref4$initialSelected;
  var createMenuReducerParametrized = useCallback(function () {
    return createMenuReducer({
      selectable: selectable,
      interactive: interactive,
      closeOnSelect: closeOnSelect,
      multiselect: multiselect
    });
  }, [selectable, interactive, closeOnSelect, multiselect]);
  var reducer = useMemo(function () {
    return externalReducer ? externalReducer : createMenuReducerParametrized();
  }, [externalReducer, createMenuReducerParametrized]);
  var finalInit = externalInit ? externalInit : init;
  return useReducer(reducer, {
    initialSelected: initialSelected,
    initialOpen: initialOpen,
    selectable: selectable,
    interactive: interactive,
    closeOnSelect: closeOnSelect,
    multiselect: multiselect,
    options: options
  }, finalInit);
};

export var useMenu = function useMenu(_ref5) {
  var id = _ref5.id,
      _ref5$initialSelected = _ref5.initialSelected,
      initialSelected = _ref5$initialSelected === void 0 ? [] : _ref5$initialSelected,
      _ref5$initialOpen = _ref5.initialOpen,
      initialOpen = _ref5$initialOpen === void 0 ? false : _ref5$initialOpen,
      onSelect = _ref5.onSelect,
      reducer = _ref5.reducer,
      init = _ref5.init,
      _ref5$config = _ref5.config;
  _ref5$config = _ref5$config === void 0 ? {} : _ref5$config;
  var _ref5$config$selectab = _ref5$config.selectable,
      selectable = _ref5$config$selectab === void 0 ? false : _ref5$config$selectab,
      _ref5$config$interact = _ref5$config.interactive,
      interactive = _ref5$config$interact === void 0 ? true : _ref5$config$interact,
      _ref5$config$closeOnS = _ref5$config.closeOnSelect,
      closeOnSelect = _ref5$config$closeOnS === void 0 ? true : _ref5$config$closeOnS,
      _ref5$config$multisel = _ref5$config.multiselect,
      multiselect = _ref5$config$multisel === void 0 ? false : _ref5$config$multisel,
      _ref5$config$popperMo = _ref5$config.popperModifiers,
      popperModifiers = _ref5$config$popperMo === void 0 ? DEFAULT_POPPER_MODIFIERS : _ref5$config$popperMo,
      _ref5$config$placemen = _ref5$config.placement,
      placement = _ref5$config$placemen === void 0 ? "bottom" : _ref5$config$placemen,
      _ref5$config$strategy = _ref5$config.strategy,
      strategy = _ref5$config$strategy === void 0 ? "absolute" : _ref5$config$strategy,
      _ref5$options = _ref5.options,
      options = _ref5$options === void 0 ? [] : _ref5$options;
  var controlRef = useRef(null);
  var menuListRef = useRef(null);

  var _useMenuReducer = useMenuReducer({
    selectable: selectable,
    interactive: interactive,
    closeOnSelect: closeOnSelect,
    multiselect: multiselect,
    options: options,
    reducer: reducer,
    init: init,
    initialOpen: initialOpen,
    initialSelected: initialSelected
  }),
      _useMenuReducer2 = _slicedToArray(_useMenuReducer, 2),
      state = _useMenuReducer2[0],
      dispatch = _useMenuReducer2[1];

  useEffect(function () {
    var listener = function listener() {
      dispatch(mouseUpAction());
    };

    window.addEventListener("mouseup", listener);
    return function () {
      window.removeEventListener("mouseup", listener);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  var focusedIndex = useMemo(function () {
    return state.options.findIndex(function (o) {
      return o === state.focused;
    });
  }, [state.options, state.focused]);
  useClickAway({
    current: menuListRef.current
  }, function (event) {
    var control = controlRef.current;

    if (event.target !== control && (event.target || !(control !== null && control !== void 0 && control.contains(event.target)))) {
      dispatch(clickedAwayAction());
    }
  });

  var _usePopper = usePopper(controlRef.current, menuListRef.current, {
    modifiers: popperModifiers,
    placement: placement,
    strategy: strategy
  }),
      popperAttributes = _usePopper.attributes,
      popperStyles = _usePopper.styles,
      forceUpdate = _usePopper.forceUpdate;

  useKey(function (e) {
    return controlledKeys.includes(e.key);
  }, function (e) {
    if (state.open) {
      e.preventDefault();
    }

    dispatch(keyPressedAction({
      key: e.key,
      isControlFocused: document.activeElement === controlRef.current
    }));
  });
  useIsomorphicLayoutEffect(function () {
    forceUpdate === null || forceUpdate === void 0 ? void 0 : forceUpdate();
  }, [state.open]);
  useEffect(function () {
    if (state.lastTriggeredItem && !selectable) {
      onSelect === null || onSelect === void 0 ? void 0 : onSelect([state.lastTriggeredItem]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [state.lastTriggeredItem]);
  useEffect(function () {
    if (state.previous && state.selected !== state.previous) {
      onSelect === null || onSelect === void 0 ? void 0 : onSelect(_toConsumableArray(state.selected), _toConsumableArray(state.previous));
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [state.selected, state.previous]);
  var typedDispatch = dispatch;
  var typedState = state;
  return {
    focusedIndex: focusedIndex,
    open: state.open,
    state: typedState,
    dispatch: typedDispatch,
    interactive: interactive || selectable,
    // eslint-disable-next-line object-shorthand
    getControlProps: function getControlProps() {
      var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _onClick = _ref6.onClick,
          _ref7 = _ref6.ref,
          _onBlur = _ref6.onBlur,
          style = _ref6.style,
          className = _ref6.className,
          rest = _objectWithoutProperties(_ref6, _excluded);

      return _objectSpread({
        'aria-haspopup': "listbox",
        onClick: function onClick(e) {
          dispatch(controlClickedAction());
          _onClick === null || _onClick === void 0 ? void 0 : _onClick(e);
        },
        onBlur: function onBlur(e) {
          if (!state.menuContainerMouseDown) {
            dispatch(controlBlurredAction());
            _onBlur === null || _onBlur === void 0 ? void 0 : _onBlur(e);
          }
        },
        ref: function ref(r) {
          if (_ref7) {
            if (typeof _ref7 === "function") {
              _ref7(r);
            } else {
              var mutableRef = _ref7;
              mutableRef.current = r;
            }
          }

          controlRef.current = r;
        },
        style: _objectSpread(_objectSpread({}, popperStyles.reference), style),
        className: className
      }, rest);
    },
    // eslint-disable-next-line object-shorthand
    getMenuListProps: function getMenuListProps() {
      var _ref8 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _onMouseLeave = _ref8.onMouseLeave,
          _ref9 = _ref8.ref,
          props = _objectWithoutProperties(_ref8, _excluded2);

      var focusedId = props.id ? props.id : focusedIndex >= 0 ? focusedIndex : null;
      return _objectSpread({
        role: "listbox",
        tabindex: -1,
        'aria-activedescendant': focusedId !== undefined && focusedId !== null ? focusedId.toString() : undefined,
        interactive: true,
        onMouseLeave: function onMouseLeave(e) {
          dispatch(menuMouseLeftAction());
          _onMouseLeave === null || _onMouseLeave === void 0 ? void 0 : _onMouseLeave(e);
        },
        ref: function ref(r) {
          if (_ref9) {
            if (typeof _ref9 === "function") {
              _ref9(r);
            } else {
              var mutableRef = _ref9;
              mutableRef.current = r;
            }
          }

          menuListRef.current = r;
        }
      }, props);
    },
    getPopperAttributes: function getPopperAttributes() {
      var style = _objectSpread({}, popperStyles.popper);

      if (!state.open) {
        style.display = "none";
      }

      return _objectSpread(_objectSpread({}, popperAttributes.popper), {}, {
        style: style
      });
    },
    // eslint-disable-next-line object-shorthand
    getMenuContainerProps: function getMenuContainerProps() {
      var _ref10 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var _onMouseDown = _ref10.onMouseDown,
          rest = _objectWithoutProperties(_ref10, _excluded3);

      return _objectSpread({
        onMouseDown: function onMouseDown(e) {
          dispatch(menuContainerMouseDownAction());
          _onMouseDown === null || _onMouseDown === void 0 ? void 0 : _onMouseDown(e);
        }
      }, rest);
    },
    // eslint-disable-next-line object-shorthand
    getItemProps: function getItemProps(item, index) {
      var _ref11 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var _onMouseEnter = _ref11.onMouseEnter,
          _onClick2 = _ref11.onClick,
          rest = _objectWithoutProperties(_ref11, _excluded4);

      var selected = state.selected.some(function (el) {
        return el === item;
      });
      return _objectSpread({
        id: "".concat(id, "-").concat(index),
        value: item.value,
        key: item.value,
        selected: selected,
        focused: state.focused === item,
        onMouseEnter: function onMouseEnter(e) {
          dispatch(itemMouseEnteredAction(item));
          _onMouseEnter === null || _onMouseEnter === void 0 ? void 0 : _onMouseEnter(e);
        },
        onClick: function onClick(e) {
          dispatch(itemClickedAction(item));
          _onClick2 === null || _onClick2 === void 0 ? void 0 : _onClick2(e);
        },
        role: "option",
        'aria-selected': selected
      }, rest);
    },
    getSelectedItems: function getSelectedItems() {
      return state.selected;
    }
  };
};
export default useMenu;